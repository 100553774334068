import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import './App.css';
import './assets/kenyan.otf';
import Services from "./pages/Services";
import News from "./pages/News";
import Article from "./pages/Article";
const App = () => {
  const { t } = useTranslation();

  return (
      <Router>
        <Header />
        <main>
          <Routes>
            <Route path="/" Component={Home} />
            <Route path="/about" Component={About} />
            <Route path="/services" Component={Services} />
            <Route path="/news" Component={News} />
            <Route path="/article/:currentLocale/:documentId" element={<Article />} />
            <Route path="/contact" Component={Contact} />
          </Routes>
        </main>
        <Footer />
      </Router>
  );
};

export default App;
