import { useTranslation } from 'react-i18next';
import {Card, CardBody, CardTitle, Col, Container, Row} from "react-bootstrap";
import blueye from '../assets/blueeye.png';
import ipad from '../assets/ipad.png';
import {PreventOrphanWords} from "../utils/func";
import React, {useEffect, useMemo, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import i18n from "i18next";
import "./News.css";
import {format} from "date-fns";

const News = () => {
    const { t } = useTranslation();
    const [articles, setArticles] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('');
    const navigate = useNavigate();
    const currentLocale = i18n.language;
    const filters = [
        { key: 'projects', name: t('filters.projects') },
        { key: 'partnership', name: t('filters.partnership') },
        { key: 'certificate', name: t('filters.certificate') },
    ];

    useEffect(() => {
        axios.get(`https://admin.bluecheck.pl/api/articles?locale=${currentLocale === 'pl-Pl' || currentLocale === 'pl' ? 'pl' : 'en'}&populate=*`
            + (selectedFilter ? `&filters[$and][0][tags][name][$eq]=${selectedFilter}` : ``),
            { headers: { Authorization: `Bearer e2f99e3489ca281eca99ecf6c53001bcc370f41733990527d33e935e06af13caa5832e8f56cf650ff2e8f41dae1ee48d6e0a65c84e4a3ff173891a9bcf8c96b36f2c32d4d9980b05f80dbb08b9bad74d6905bcf3ccbee13f652cae598ed45a8164e341724eb6b49b2bd2bd31ea516c63854aade689de6aa8fda2c5bc0051012c` }}
        )
            .then(response => setArticles(response.data.data))
            .catch(error => console.error(error));
    }, [currentLocale, selectedFilter]);

    return useMemo(() => (
        <Container className="mb-4">
            <h1 className="my-4 fw-bold">{t('news.title')}</h1>

            <div className="d-inline-flex gap-2">
                <p style={{fontSize: '1rem'}}>{t('news.filters')}</p>
                {filters.map(filter => (
                    // <div className={`filter-button shadow-sm rounded-2 px-4 ${selectedFilter === filter.key ? 'selected' : ''}`}>
                        <a onClick={() => {
                            selectedFilter === filter.key ? setSelectedFilter('') : setSelectedFilter(filter.key)
                        }} className={`filter-button px-2 ${selectedFilter === filter.key ? 'selected' : ''}`}>{filter.name}</a>
                    // </div>
                ))}
            </div>

            {!!articles && articles.length > 0 &&
                (<div>
                {articles.map(article => (
                    <div>
                        <div key={article.documentId}
                             onClick={() => navigate(`/article/${currentLocale}/${article.documentId}`)}
                             style={{
                                 cursor: 'pointer'
                             }}
                             className="shadow-sm d-flex flex-row gap-4 px-4 py-3 my-4 position-relative article">
                            {article?.cover?.formats?.small?.url && <img src={`https://admin.bluecheck.pl${article?.cover?.formats?.small?.url}`}
                                                         alt=""
                                                         className="rounded"
                                                         width={100}
                                                         height={100}
                            />}
                            <div className="d-flex flex-column">
                                <h5 className="my-2" style={{ fontWeight: 'bold'}}>{article.title}</h5>
                                <p>{article.desc}</p>
                                <p className="published">{format(article.publishedAt, "dd-MM-yyyy")}</p>
                            </div>
                            {article.tags[0]?.name &&
                                <div className="tag position-absolute">
                                    {t(`filters.${article.tags[0]?.name}`)}
                                </div>}
                            {/*{article.tags[0]?.name &&*/}
                            {/*    <div className="position-absolute bg-warning text-dark p-1 rounded"*/}
                            {/*         style={{top: '1rem', right: '1rem'}}>*/}
                            {/*        {t(`filters.${article.tags[0]?.name}`)}*/}
                            {/*    </div>}*/}
                        </div>

                        {/*<hr className="p-2"/>*/}
                    </div>
                ))}
            </div>)}

            {(!articles || articles.length === 0) && (
                <div className="text-center">
                    <h2>{t('news.empty')}</h2>
                </div>
            )}
        </Container>
    ), [articles]);
};

export default News;
