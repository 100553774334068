import React, {useEffect, useRef, useState} from 'react';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {PreventOrphanWords} from "../utils/func";
import './Contact.css';
import linkedin from "../assets/linkedin.png";

const Contact = () => {
    const { register, setValue, getValues,  handleSubmit, formState: { errors } } = useForm();
    const { t } = useTranslation();
    const [result, setResult] = useState({});
    const hCaptchaRef = useRef();
    const toastConfig = {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
        transition: Slide,
    };

    const onHCaptchaChange = (token) => {
        setValue("h-captcha-response", token);
    };

    const onSubmit = async (data, event) => {
        setResult({ type: 'info', value: t('contact.form.sending') });
        const formData = new FormData(event.target);
        formData.append("access_key", "cd814332-fe69-407a-9e32-6b0900a7ec84");
        formData.append("subject", "Nowa wiadomość z BlueCheck");
        formData.append("from_name", "BlueCheck");

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });

        const responseData = await response.json();

        if (responseData.success) {
            setResult({ type: 'success', value: t('contact.form.success') });
            event.target.reset();
            hCaptchaRef.current.resetCaptcha();
        } else {
            setResult({ type: 'error', value: responseData.message});
        }
    };

    useEffect(() => {
        result?.type === 'error' && toast.error(result.value, toastConfig);
        result?.type === 'success' && toast.success(result.value, toastConfig);
        result?.type === 'info' && toast.info(result.value, toastConfig);
    }, [result]);

    const options = [
        { value: 'Marine services', label: t('contact.form.sectorOptions.marine') },
        { value: 'Shipping', label: t('contact.form.sectorOptions.environmental') },
        { value: 'Fishing', label: t('contact.form.sectorOptions.fishing') },
        { value: 'Offshore Wind', label: t('contact.form.sectorOptions.offshore') },
        { value: 'Oil and Gas', label: t('contact.form.sectorOptions.oilGas') },
        { value: 'Ports and Marinas', label: t('contact.form.sectorOptions.portsMarines') },
        { value: 'Search and Rescue', label: t('contact.form.sectorOptions.searchRescue') },
        { value: 'Shipping', label: t('contact.form.sectorOptions.shipping') },
        { value: 'Underwater Construction', label: t('contact.form.sectorOptions.under') },
        { value: 'Water Management', label: t('contact.form.sectorOptions.management') },
        { value: 'Other', label: t('contact.form.sectorOptions.other') },
    ]

    return (
        <Container className="mb-4">
            {/*<LazyLoad height={200} offset={100} once>*/}
            {/*    <img src={blob} alt="blob" className="blob"/>*/}
            {/*</LazyLoad>*/}

            <Row className="justify-content-between mt-4">
                <Col md={6}>
                    <h2 className="text-center mb-4 fw-bold border-box">{t('contact.title')}</h2>
                    <PreventOrphanWords text={t('contact.description')} />
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="h-captcha" data-captcha="true"></div>
                        <Form.Group controlId="formName" className="mt-3">
                            <Form.Label>{t('contact.form.name')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('contact.form.namePlaceholder')}
                                {...register('name', { required: true })}
                            />
                            {errors.name && <span className="text-danger">{t('contact.form.required')}</span>}
                        </Form.Group>

                        <Form.Group controlId="formReplyto" className="mt-3">
                            <Form.Label>{t('contact.form.email')}</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder={t('contact.form.emailPlaceholder')}
                                {...register('replyto', { required: true })}
                            />
                            {errors.replyto && <span className="text-danger">{t('contact.form.required')}</span>}
                        </Form.Group>

                        <Form.Group controlId="formSector" className="mt-3">
                            <Form.Label>{t('contact.form.sector')}</Form.Label>
                            <Form.Control
                                as="select"
                                {...register('sector', { required: true })}>
                                <option value="" disabled>{t('contact.form.sector')}</option>
                                {options.map((option, index) => (
                                    <option key={index} value={option.label}>{option.label}</option>
                                ))}
                            </Form.Control>
                            {errors.sector && <span className="text-danger">{t('contact.form.required')}</span>}
                        </Form.Group>

                        <Form.Group controlId="formMessage" className="my-3">
                            <Form.Label>{t('contact.form.message')}</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                placeholder={t('contact.form.messagePlaceholder')}
                                {...register('message', { required: true })}
                            />
                            {errors.message && <span className="text-danger">{t('contact.form.required')}</span>}
                        </Form.Group>

                        <HCaptcha
                            sitekey="50b2fe65-b00b-4b9e-ad62-3ba471098be2"
                            reCaptchaCompat={false}
                            onVerify={onHCaptchaChange}
                            ref={hCaptchaRef}
                        />

                        <Button variant="primary" type="submit" className="mt-2 w-100">
                            {t('contact.form.submit')}
                        </Button>
                    </Form>
                </Col>
                <Col md={6} className="mt-4">
                    <div class="text-center">
                        <a href="http://linkedin.com/company/bluecheck-sp-z-o-o"
                           target="_blank"
                           rel="noopener noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                 className="bi bi-linkedin" viewBox="0 0 16 16">
                                <path
                                    d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
                            </svg>
                        </a>
                    </div>
                    <PreventOrphanWords className="text-center mb-4 font-size-2 mt-4 fw-semibold" text={"BlueCheck Sp. z o.o. / ul. Cyfrowa 6 / Szczecin 71-441"} />
                    <PreventOrphanWords className="text-center mb-4 font-size-2 fw-semibold" text={"NIP: 8513310402 / REGON: 528341318"} />

                    <div>
                        <iframe width="100%" height="400" frameBorder="0" scrolling="no" marginHeight="0"
                                marginWidth="0"
                                src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Cyfrowa%206,%20Szczecin+(BlueCheck)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                            <a href="https://www.gps.ie/">gps tracker sport</a></iframe>
                    </div>
                </Col>
            </Row>
            <ToastContainer />
        </Container>
    );
};

export default Contact;
