import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import video2 from '../assets/video2.mp4';
import {PreventOrphanWords} from "../utils/func";
import "./Home.css";

const Home = () => {
    const { t } = useTranslation();

    return (
        <Container className="home-content">
            <div className="video-background">
                <LazyLoad height={200} offset={100} once>
                    <video autoPlay loop muted playsInline className="video">
                        <source src={video2} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </LazyLoad>
            </div>
            <div className="home-text text-white text-center p-3 rounded mt-4">
                <h1 className="fw-bold pb-2">
                    {t('home.welcome')} <span style={{ whiteSpace: 'nowrap' }}>{t('home.company')}</span>
                </h1>
                <h2 className="fw-semibold pb-3">{t('home.title')}</h2>
                <PreventOrphanWords text={t('home.description')} />
            </div>
            {/*<Row className="mt-4">*/}
            {/*    <Col md={6} className="text-center">*/}
            {/*        <h2>Our Services</h2>*/}
            {/*        <p>We offer a wide range of services tailored to the maritime industry.</p>*/}
            {/*    </Col>*/}
            {/*    <Col md={6} className="text-center">*/}
            {/*        <h2>Contact Us</h2>*/}
            {/*        <p>Get in touch with us for more information about our services.</p>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </Container>
    );
};

export default Home;
