import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import i18n from "i18next";
import { format } from "date-fns";
import Contact from "./Contact";
import "./Article.css";
import {useTranslation} from "react-i18next";
import {PreventOrphanWords} from "../utils/func";

function Article() {
    const { t } = useTranslation();
    const { documentId } = useParams();
    const [article, setArticle] = useState(null);
    const currentLocale = i18n.language;


    useEffect(() => {
        axios.get(`https://admin.bluecheck.pl/api/articles/${documentId}?locale=${currentLocale === 'pl-Pl' || currentLocale === 'pl' ? 'pl' : 'en'}&populate=*`, { headers: { Authorization: `Bearer e2f99e3489ca281eca99ecf6c53001bcc370f41733990527d33e935e06af13caa5832e8f56cf650ff2e8f41dae1ee48d6e0a65c84e4a3ff173891a9bcf8c96b36f2c32d4d9980b05f80dbb08b9bad74d6905bcf3ccbee13f652cae598ed45a8164e341724eb6b49b2bd2bd31ea516c63854aade689de6aa8fda2c5bc0051012c` }})
            .then(response => setArticle(response.data.data))
            .catch(error => console.error(error));
    }, [documentId, currentLocale]);

    if (!article) return <div>Loading...</div>;
    console.log(article)

    return (
        <div className="m-md-auto small bg-light-subtle p-4 article-content">
            <h1 className="my-4 article-title">{article.title}</h1>
            {article?.cover?.url && <img src={`https://admin.bluecheck.pl${article?.cover?.url}`}
                                                         alt=""
                                                         className="rounded w-100"
            />}
            <h5 className="article-desc">{article.desc}</h5>
            <div className="d-inline-flex align-items-center w-100 justify-content-between">
                <p className="published">{format(article.publishedAt, "dd-MM-yyyy H:mm")}</p>
                {article.tags[0]?.name &&
                    <p class="tag-article">{t(`filters.${article.tags[0]?.name}`)}</p>
                    }
            </div>
            <hr/>
            <p><ReactMarkdown>{article.content}</ReactMarkdown></p>
        </div>
    );
}
export default Article;
