import React from 'react';
import { useTranslation } from 'react-i18next';
import {Card, CardBody, CardTitle, Col, Container, Row} from "react-bootstrap";
import blueye from '../assets/blueeye.png';
import ipad from '../assets/ipad.png';
import {PreventOrphanWords} from "../utils/func";
import "./Services.css";

const Services = () => {
    const { t } = useTranslation();

    const cards = [
        {
            title: t('services.value1Title'),
            description: t('services.value1')
        },
        {
            title: t('services.value2Title'),
            description: t('services.value2')
        },
        {
            title: t('services.value3Title'),
            description: t('services.value3')
        },
        {
            title: t('services.value4Title'),
            description: t('services.value4')
        },
        {
            title: t('services.value5Title'),
            description: t('services.value5')
        },
        {
            title: t('services.value6Title'),
            description: t('services.value6')
        }
    ]

    return (
        <Container className="mb-4">
            <Row className="mt-4 mb-5">
                <Col xs={{ order: 2 }} md={{ span: 6, order: 1 }} className="text-center">
                    <img src={blueye} alt="Blueeye X3" width={300} height={200} className="img-fluid"/>
                </Col>
                <Col xs={{ order: 1 }} md={{ span: 6, order: 2 }} className="m-auto">
                    <h2 className="fw-bold text-center text-md-start">{t('technology.title')}</h2>
                    <PreventOrphanWords className="m-auto justify-text" text={t('technology.description')} />
                </Col>
            </Row>
            <Row className="mt-4 mb-5">
                <Col xs={{ order: 2 }} md={{ span: 6, order: 2 }} className="text-center">
                    <img src={ipad} alt="Blueeye X3 controller" width={300} height={200} className="img-fluid"/>
                </Col>
                <Col xs={{ order: 1 }} md={{ span: 6, order: 1 }} className="m-auto">
                    <h2 className="fw-bold text-center text-md-start">{t('client.title')}</h2>
                    <PreventOrphanWords className="m-auto justify-text" text={t('client.description')}/>
                </Col>
            </Row>
            <Row className="justify-content-between mt-4">
                <Col md={12} className="text-center">
                    <h1 className="fw-bold">{t('services.title')}</h1>
                </Col>
            </Row>
            <Row className="justify-content-between mt-4">
                {cards.map((card, index) => (
                    <Col xs={12} sm={6} md={4} className="h-100 p-2" key={index}>
                        <Card className="p-2">
                            <CardTitle className="text-center fw-bold">
                                <PreventOrphanWords text={card.title}/>
                            </CardTitle>
                            <CardBody className="fst-italic justify-text">
                                <PreventOrphanWords text={card.description}/>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
            {/*<Row className="justify-content-center mt-4">*/}
            {/*    <Link to="/contact" className="btn btn-info">*/}
            {/*        {t('site.contact')}*/}
            {/*    </Link>*/}
            {/*</Row>*/}
        </Container>
    );
};

export default Services;
